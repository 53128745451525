/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { Tabs, Tab } from 'react-bootstrap';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';

const AFKGuidesCrazedShellbrute: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Dream Realm - Crazed Shellbrute</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_brute.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Dream Realm - Crazed Shellbrute</h1>
          <h2>
            Dream Realm Crazed Shellbrute guide that explains the boss mechanics
            and how to beat it!
          </h2>
          <p>
            Last updated: <strong>17/Feb/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Crazed Shellbrute" />
        <p>
          <strong>Crazed Shellbrute</strong> is one of the 8 available Dream
          Realm bosses you will encounter in AFK Journey in Season 3. In this
          guide we will go over the boss mechanics and the best characters and
          teams to use against him.
        </p>
        <SectionHeader title="What’s the meta team?" />
        <p>
          Realistically speaking,{' '}
          <strong>
            your meta team will depend on your specific box, and your account
            focus
          </strong>
          , so use your highest rarity DPS carry characters, along with whoever
          can best boost their damage.{' '}
          <strong className="gold">
            Try running Dual carry, as currently there is no way to boost a
            Hypercarry’s damage high enough to make it worth running only one
          </strong>
          . Keep faction bonus in mind but don’t run suboptimal characters just
          to force it.
        </p>
        <p>
          Still, below you can find a few example teams. Big thanks to{' '}
          <StaticImage
            src="../../../images/afk/icons/afkanalytica.png"
            alt="AFK Analytica"
          />{' '}
          <strong className="yellow">AFK Analytica</strong> for the team
          building tool. You can find their website{' '}
          <a
            href="https://www.afkanalytica.com/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          and their discord{' '}
          <a
            href="https://www.discord.gg/analytica"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <SectionHeader title="Season 3 Teams" />
        <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs-season"
          className="nav-tabs"
        >
          <Tab
            eventKey="Team1"
            title={<>Team #1 - Best Team</>}
            className="with-bg"
          >
            <h5>Team #1 - Best Team (Whale)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/shell_1.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>森栀 Michelle / 半月 (Yaphalla)</strong>.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="sinbad" enablePopover /> is
                the main carry and debuffer.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="odie" enablePopover /> can
                  be used as an alternative, but then{' '}
                  <AFKCharacter mode="inline" slug="sonja" enablePopover /> has
                  to be outside of the
                  <AFKCharacter
                    mode="inline"
                    slug="elijah-and-lailah"
                    enablePopover
                  />{' '}
                  link.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="sonja" enablePopover /> is the
                secondary carry and buffer.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="phraesto" enablePopover />{' '}
                acts as an extra body for the boss to eat, and energy regen
                source.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="thoran" enablePopover /> can
                  be used as an alternative.
                </li>
              </ul>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="elijah-and-lailah"
                  enablePopover
                />{' '}
                act as healers and buffers.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="mikola" enablePopover /> acts
                as buffer and healer and sacrificial unit for the boss.
              </li>
              <li>Artifacts:</li>
              <ul className="bigger-margin">
                <li>
                  <AFKItem name="Bladesummon Spell" /> - Best overall
                  performance.
                </li>
                <li>
                  <AFKItem name="Frostfall Spell" /> - Good alternative if you
                  don’t have the Bladesummon Spell.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team2"
            title={<>Team #2 - Alt Team</>}
            className="with-bg"
          >
            <h5>Team #2 - Alt Team (Phraesto + Reinier)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/shell_2.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>AKTUBE (Analytica)</strong>.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="odie" enablePopover /> is the
                main carry.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="sinbad" enablePopover /> can
                  sometimes deal more damage but their performance is very
                  similar here, so use the one you have the most investment in.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="sonja" enablePopover /> acts
                as secondary carry and buffer.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="bonnie" enablePopover /> can
                  be used as an alternative, helping buff Odie.
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="lily-may" enablePopover />{' '}
                  also works here.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="phraesto" enablePopover />{' '}
                acts as an extra body for the boss to eat, and energy regen
                source.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="thoran" enablePopover /> can
                  be used as an alternative.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> acts
                as buffer and debuffer <strong>(Mythic+ required)</strong>.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="mikola" enablePopover /> acts
                as buffer, secondary damage carry and sacrificial unit for the
                boss.
              </li>
              <li>Artifacts:</li>
              <ul className="bigger-margin">
                <li>
                  <AFKItem name="Bladesummon Spell" /> - Best overall
                  performance.
                </li>
                <li>
                  <AFKItem name="Frostfall Spell" /> - Good alternative if you
                  don’t have the Bladesummon Spell.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team3"
            title={<>Team #3 - Alt Team</>}
            className="with-bg"
          >
            <h5>Team #3 - Alt Team (Phraesto)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/shell_3.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>Kanaqin/Beef (Yaphalla)</strong>.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="sinbad" enablePopover /> is
                the main carry and debuffer.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="odie" enablePopover />
                  also performs similarly well.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="sonja" enablePopover /> acts
                as secondary carry and buffer.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="lily-may" enablePopover />{' '}
                  can be used as an alternative.
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="bonnie" enablePopover /> can
                  be used if running Odie
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="phraesto" enablePopover />{' '}
                acts as an extra body for the boss to eat, and energy regen
                source.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="thoran" enablePopover /> can
                  be used as an alternative.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="mikola" enablePopover /> acts
                as buffer and healer and sacrificial unit for the boss.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="kruger" enablePopover /> acts
                as another sacrificial unit and debuffer.
              </li>
              <li>Artifacts:</li>
              <ul className="bigger-margin">
                <li>
                  <AFKItem name="Bladesummon Spell" /> - Best overall
                  performance.
                </li>
                <li>
                  <AFKItem name="Frostfall Spell" /> - Good alternative if you
                  don’t have the Bladesummon Spell.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team4"
            title={<>Team #4 - Alt Team</>}
            className="with-bg"
          >
            <h5>Team #4 - Alt Team (Reinier)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/shell_4.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>D3R4G0N/Genhard (Yaphalla)</strong>.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="odie" enablePopover /> is the
                main carry .
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="sinbad" enablePopover />
                  also performs similarly well.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="bonnie" enablePopover /> acts
                as secondary carry and debuffer.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="lily-may" enablePopover />{' '}
                  can be used as an alternative.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="thoran" enablePopover /> acts
                as debuffer and sacrificial unit for the boss.
              </li>

              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> acts
                as buffer and debuffer <strong>(Mythic+ required)</strong>.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="sonja" enablePopover /> can
                  be used as an alternative.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="mikola" enablePopover /> acts
                as buffer and healer and sacrificial unit for the boss.
              </li>
              <li>Artifacts:</li>
              <ul className="bigger-margin">
                <li>
                  <AFKItem name="Bladesummon Spell" /> - Best overall
                  performance.
                </li>
                <li>
                  <AFKItem name="Frostfall Spell" /> - Good alternative if you
                  don’t have the Bladesummon Spell.
                </li>
              </ul>
            </ul>
          </Tab>
        </Tabs>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesCrazedShellbrute;

export const Head: React.FC = () => (
  <Seo
    title="Crazed Shellbrute | AFK Journey | Prydwen Institute"
    description="Dream Realm Crazed Shellbrute guide that explains the boss mechanics and how to beat it!"
    game="afk"
  />
);
